"use client";

import { SessionProvider } from "next-auth/react";
import posthog from "posthog-js";
import { PostHogProvider as PHProvider } from "posthog-js/react";
import { useEffect } from "react";
import PostHogPageView from "./PostHogPageView";

export function Providers({ children }) {
    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
            person_profiles: "always", // or 'always' to create profiles for anonymous users as well
            capture_pageview: false,
            capture_pageleave: true,
        });
    }, []);

    return (
        <PHProvider client={posthog}>
            <PostHogPageView />
            <SessionProvider>{children}</SessionProvider>
        </PHProvider>
    );
}
